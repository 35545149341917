import { UserProfile } from "../../../types";

export const userProfile: UserProfile = {
  dateOfBirth: "12.12.1912",
  email: "test@test.pl",
  firstName: "Konrad",
  id: "30",
  lastName: "Kowalski",
  pesel: "12121251212",
  phoneNumber: "609165419",
  phoneNumberPrefix: "48",
  providerId: "bcbc1174-a6f6-4c2a-b424-8274ac70ee75",
  termsAgreement: true,
  marketingAgreement: true,
  commercialAgreement: false,
  address: {
    city: "Warszawa",
    postCode: "02-221",
    streetName: "Polanka",
    streetNumber: "11",
    flatNumber: "",
  },
  nfzActive: false,
  popups: {
    makeDeclaration: true,
    changeDeclarationFacility: true,
    subscriptionEnd: true,
  },
  document: {
    type: "PESEL",
    number: "12121251212",
  },
};

export default userProfile;
